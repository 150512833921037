import { FC, memo } from 'react';
import { Select, Space } from 'antd';
import { Icon } from '@/components';

const Currency: FC = (props) => (
  <Select {...props} defaultValue="weth" style={{ width: 120 }} size="large">
    <Select.Option value="weth">
      <Space size="small">
        <Icon type="weth" />
        WETH
      </Space>
    </Select.Option>
  </Select>
);

export default memo(Currency);

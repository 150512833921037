import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Col, Descriptions, Row, Table } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers } from 'ethers';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ERC721Tradable from '@/contracts/ERC721Tradable.json';

interface Recipient {
  recipient: string;
  points: BigNumber;
}

interface NFT {
  name: string;
  description: string;
  image: string;
  tokenId: string;
}

const columns = [
  {
    title: 'Recipient',
    dataIndex: 'recipient',
  },
  {
    title: 'Points',
    dataIndex: 'points',
    render: (item: any) => `${item.toNumber()}%`,
  },
];

const Home: FC = () => {
  const { library } = useWeb3React();

  const navigate = useNavigate();

  const [items, setItems] = useState<NFT[]>([]);
  // const [balance, setBalance] = useState<BigNumber>();
  const [sellerFeeBasisPoints, setSellerFeeBasisPoints] = useState<BigNumber>();
  const [feeRecipients, setFeeRecipients] = useState<Recipient[]>([]);
  const [saleRecipients, setSaleRecipients] = useState<Recipient[]>([]);
  const { address } = useParams<any>();

  const loadData = useCallback(async () => {
    if (library && address) {
      const contract = new ethers.Contract(
        address,
        ERC721Tradable.abi,
        library
      );

      setSellerFeeBasisPoints(await contract.getSellerFeeBasisPoints());
      setFeeRecipients(await contract.getFeeRecipients());
      setSaleRecipients(await contract.getSaleRecipients());
      // setBalance(await contract.balanceOf());

      const events = await contract.queryFilter({});
      console.log(events);
      console.log(
        events.filter(
          (p) =>
            p.event === 'Transfer' &&
            p.args?.from === '0x0000000000000000000000000000000000000000'
        )
      );
      const res: any = await Promise.all(
        events
          .filter(
            (p) =>
              p.event === 'Transfer' &&
              p.args?.from === '0x0000000000000000000000000000000000000000'
          )
          .map(async (p) => {
            const tokenId = (p.args![2] as BigNumber).toString();
            const url = await contract.tokenURI(p.args![2]);
            const content = await axios.get(url);
            return { ...content, tokenId };
          })
      );
      console.log(res);
      setItems(res);
    }
  }, [library, address]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleCreate = () => {
    navigate(`/collection/${address}/create`);
  };

  return (
    <div className="container mx-auto py-20">
      <Button type="primary" onClick={handleCreate}>
        Create NFT
      </Button>
      <Descriptions layout="vertical" column={1}>
        <Descriptions.Item label="Sale Recipients">
          <Table
            bordered
            rowKey="recipient"
            columns={columns}
            dataSource={saleRecipients}
            pagination={false}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Seller Fee Basis Points">
          {sellerFeeBasisPoints?.toNumber()}%
        </Descriptions.Item>
        <Descriptions.Item label="Fee Recipients">
          <Table
            bordered
            rowKey="recipient"
            columns={columns}
            dataSource={feeRecipients}
            pagination={false}
          />
        </Descriptions.Item>
      </Descriptions>
      <Row gutter={[30, 30]}>
        {items.map((item) => (
          <Col key={item.tokenId}>
            <Link to={`/assets/${address}/${item.tokenId}`}>
              <div className="border">
                <div className="w-20">
                  <img src={item.image} alt="asset" />
                </div>
                <div className="text-center py-2">{item.name}</div>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Home;

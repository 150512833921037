import { FC, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Space } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, constants, Contract, utils } from 'ethers';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import Market from '@/contracts/MarketV2.json';
import ERC20 from '@/contracts/ERC20.json';
import { marketAddress } from '@/config';
import useSWR from 'swr';
import { NFT, Order } from '../interface';
import DetailHeader from './DetailHeader';
import { getOrderHash, toOrderTuple } from '../utils';
import OrderList from './OrderList';
import { Icon } from '@/components';
import Base from './Base';
import PriceInput from './PriceInput';
import dayjs from 'dayjs';
import axios from 'axios';

const WETHRinkebyAddress = '0xc778417e063141139fce010982780140aa0cd5ab';
// const WETHProxyAddress = '0x43d6914F10151A3dB15D7aB32bf4c5cD44c48210';

function getMinPriceOrder(orders: Order[]) {
  return _.minBy(orders, (order) => BigNumber.from(order.price));
}

function getMaxPriceOrder(orders: Order[]) {
  return _.minBy(orders, (order) => BigNumber.from(order.price));
}

const Detail: FC = () => {
  const { library, account, chainId } = useWeb3React();

  const { address, tokenId } = useParams<any>();

  const { data } = useSWR<NFT>(`/nft/${address}/${tokenId}`);
  const { data: orders = [] } = useSWR<Order[]>(`/order/${address}/${tokenId}`);
  console.log(data);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const listingOrders = useMemo(
    () => orders.filter((p) => p.type === 1),
    [orders]
  );

  const offerOrders = useMemo(
    () => orders.filter((p) => p.type === 2),
    [orders]
  );

  const order = useMemo(() => getMinPriceOrder(listingOrders), [listingOrders]);
  const maxOrder = useMemo(() => getMaxPriceOrder(offerOrders), [offerOrders]);
  const isOwner = useMemo(
    () => data?.owner.toLowerCase() === account?.toLowerCase(),
    [account, data?.owner]
  );
  if (!data) {
    return <div />;
  }
  const { owner } = data;

  const handleBuy = async (order: Order, amount = 1) => {
    if (library && account) {
      const signer = library.getSigner();
      const contract = new Contract(marketAddress, Market.abi, signer);
      const res = await contract.buy(
        toOrderTuple(order),
        order.signature,
        amount,
        {
          value: order.price,
        }
      );
      console.log(res);
    }
  };

  const handleMakeOffer = async () => {
    const values = await form.validateFields();
    const price = utils.parseEther(`${values.price.value}`);
    if (library) {
      const signer = library.getSigner();
      const contract = new Contract(WETHRinkebyAddress, ERC20, signer);
      const allowance: BigNumber = await contract.allowance(
        account,
        marketAddress
      );
      if (allowance.lt(price)) {
        const approve = await contract.approve(
          marketAddress,
          constants.MaxUint256
        );
        await approve.wait();
      }
      const order = {
        tokenAddress: address!,
        tokenId: tokenId!,
        maker: account!,
        price: price.toString(),
        amount: 1,
        listingTime: dayjs().unix(),
        expirationTime: dayjs().add(7, 'day').unix(),
        salt: `${dayjs().unix()}`,
        type: 2,
      };
      const hash = getOrderHash(order, chainId);
      console.log(hash);
      const signature = await signer.signMessage(utils.arrayify(hash));
      await axios.post('/order', {
        ...order,
        hash,
        signature,
      });
    }
  };

  return (
    <>
      {isOwner && <DetailHeader {...data} orders={orders} />}
      <div className="max-w-screen-xl mx-auto py-8">
        <Row gutter={[30, 30]}>
          <Col span={10}>
            <div
              className="rounded-lg mb-8 w-full pb-full border relative"
              style={{ background: `#${data.backgroundColor}` }}
            >
              <div className="absolute w-full h-full flex justify-center items-center">
                <img src={data.image} alt="asset" />
              </div>
            </div>
            <Base {...data} />
          </Col>
          <Col span={14}>
            <Link to={`/collection/${address}`} className="text-lg">
              {address}
            </Link>
            <div className="text-3xl font-bold py-4">{data.name}</div>
            <Space className="pb-4" size="large">
              <Space size="small">
                Owner by
                <Link to={`/${owner}`}>
                  {owner.substring(2, 9).toUpperCase()}
                </Link>
              </Space>
              <a
                rel="noreferrer"
                href={`https://testnets.opensea.io/assets/${address}/${tokenId}`}
                target="_blank"
              >
                View OpenSea
              </a>
            </Space>
            <Space className="w-full" size="large" direction="vertical">
              {(order || maxOrder || !isOwner) && (
                <Card>
                  {(order || maxOrder) && (
                    <div className="mb-4">
                      <div className="pb-4 text-base text-secondary">
                        {order ? 'Current price' : 'Highest offer'}
                      </div>
                      <Space>
                        <Icon
                          type={order ? 'eth' : 'weth'}
                          style={{ fontSize: 20 }}
                        />
                        <div className="font-bold text-3xl leading-none -mt-1">
                          {utils.formatEther(
                            order?.price || maxOrder?.price || '0'
                          )}
                        </div>
                      </Space>
                    </div>
                  )}
                  <Space>
                    {order && (
                      <Button
                        className="w-36"
                        size="large"
                        type="primary"
                        disabled={isOwner}
                        onClick={() => handleBuy(order)}
                      >
                        Buy Now
                      </Button>
                    )}
                    {!isOwner && (
                      <Button
                        className="w-36"
                        size="large"
                        onClick={() => setVisible(true)}
                      >
                        Make Offer
                      </Button>
                    )}
                  </Space>
                </Card>
              )}
              <OrderList
                title="Listings"
                orders={listingOrders}
                isOwner={isOwner}
              />
              <OrderList
                title="Offers"
                orders={offerOrders}
                isOwner={isOwner}
              />
            </Space>
          </Col>
        </Row>
      </div>
      <Modal
        title="Make an offer"
        visible={visible}
        onOk={handleMakeOffer}
        onCancel={() => setVisible(false)}
      >
        <Form form={form} layout="vertical" requiredMark={false}>
          <Form.Item
            name="price"
            label="Price"
            style={{ marginBottom: 0 }}
            rules={[{ required: true }]}
          >
            <PriceInput />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Detail;

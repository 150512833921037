import { FC, memo, useEffect, useMemo, useState } from 'react';
import { InputNumber } from 'antd';
import Currency from './Currency';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, Contract, utils } from 'ethers';
import ERC20 from '@/contracts/ERC20.json';

interface Price {
  currency: string;
  value: number;
}

interface PriceInputProps {
  value?: Price;
  onChange?: (value: Price) => void;
}

const PriceInput: FC<PriceInputProps> = ({ value, onChange }) => {
  const { account, library } = useWeb3React();
  const [balance, setBalance] = useState<BigNumber>();

  const max = useMemo(
    () => (balance ? parseFloat(utils.formatEther(balance)) : 0),
    [balance]
  );

  useEffect(() => {
    (async () => {
      if (account && library) {
        const contract = new Contract(
          '0xc778417e063141139fce010982780140aa0cd5ab',
          ERC20,
          library
        );
        setBalance(await contract.balanceOf(account));
      }
    })();
  }, [account, library]);

  const handleChange = (val: number) => {
    onChange?.({ currency: 'weth', value: val });
  };

  return (
    <div>
      <InputNumber
        size="large"
        className="w-full"
        addonBefore={<Currency />}
        onChange={handleChange}
        min={0.0001}
        max={max}
      />
      <div className="mt-1 h-6 flex flex-row-reverse">
        {balance && (
          <div className="text-secondary">
            Balance: {utils.formatEther(balance)} WETH
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(PriceInput);

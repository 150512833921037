import { FC, useEffect } from 'react';
import { Avatar, List } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { useNavigate } from 'react-router-dom';
import metamask from './images/metamask.webp';
import walletconnect from './images/walletconnect.webp';
import { injected, RPC_URLS } from '@/config/connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

interface WalletItem {
  name: string;
  image: string;
  popular?: boolean;
}

const walletItems = [
  { name: 'Metamask', image: metamask, popular: true },
  { name: 'WalletConnect', image: walletconnect },
];

const Login: FC = () => {
  const { account, activate } = useWeb3React();

  const navigate = useNavigate();

  useEffect(() => {
    if (account) {
      navigate('/');
    }
  }, [account, navigate]);

  const handleWalletClick = (item: WalletItem) => {
    if (item.name === 'Metamask') {
      activate(injected);
    } else {
      activate(
        new WalletConnectConnector({
          rpc: { 1: RPC_URLS[1] },
          qrcode: true,
        })
      );
    }
  };

  return (
    <div className="w-fit mx-auto py-20">
      <div className="text-3xl font-bold">Connect your wallet.</div>
      <div className="text-lg py-6">
        Connect with one of our available wallet providers or create a new one.
      </div>
      <List
        bordered
        dataSource={walletItems}
        renderItem={(item) => (
          <List.Item
            className="cursor-pointer hover:shadow-hover transition-all"
            onClick={() => handleWalletClick(item)}
          >
            <List.Item.Meta
              style={{ alignItems: 'center' }}
              avatar={<Avatar shape="square" src={item.image} />}
              title={<div className="font-bold">{item.name}</div>}
            />
            {item.popular && <div>Popular</div>}
          </List.Item>
        )}
      />
    </div>
  );
};

export default Login;

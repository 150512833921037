import { Typography } from 'antd';
import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

interface NFT {
  id: string;
  name: string;
  owner: string;
  image: string;
  backgroundColor: string;
  tokenAddress: string;
  tokenId: string;
}

const { Paragraph } = Typography;

const Home: FC = () => {
  const { data = [] } = useSWR<NFT[]>('/nft');
  console.log(data);
  useEffect(() => {
    // for (let i = 1; i < 100; i++) {
    //   axios.get(`/api/nft/0x16baf0de678e52367adc69fd067e5edd1d33e3bf/${i}`);
    // }
  }, []);

  return (
    <div className="max-w-screen-xl mx-auto py-8">
      <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {data.map((item) => (
          <Link
            key={item.id}
            to={`/assets/${item.tokenAddress}/${item.tokenId}`}
            className="shadow rounded-lg hover:shadow-hover transition-all"
          >
            <div
              className="relative rounded-t-lg pb-full"
              style={{ backgroundColor: `#${item.backgroundColor}` }}
            >
              <div className="absolute flex justify-center items-center w-full h-full">
                <img
                  className="max-w-full max-h-full object-contain"
                  src={item.image}
                  alt={item.name}
                />
              </div>
            </div>
            <div className="p-4">
              <Paragraph
                ellipsis={{ rows: 2 }}
                className="text-xl font-bold h-14 text-black"
              >
                {item.name}
              </Paragraph>
              <div className="truncate text-gray-500">{item.owner}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Home;

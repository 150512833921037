import { createFromIconfontCN } from '@ant-design/icons';

export { default as Radio } from './radio';

export { default as Footer } from './footer';

export { default as InfiniteScroll } from './InfiniteScroll';

export const Icon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_3127018_ej5o8b5af1j.js',
});

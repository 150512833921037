import { FC, memo } from 'react';
import { Collapse, Descriptions } from 'antd';
import { formatAddress } from '@/utils';
import { NFT } from '../interface';

const { Panel } = Collapse;

const Base: FC<NFT> = ({ description, standard, tokenAddress, tokenId }) => {
  return (
    <Collapse defaultActiveKey={['description']} expandIconPosition="right">
      <Panel
        showArrow={false}
        collapsible="disabled"
        header="Description"
        key="description"
      >
        {description}
      </Panel>
      <Panel header="Properties" key="properties">
        {description}
      </Panel>
      <Panel header="Details" key="details">
        <Descriptions
          size="small"
          colon={false}
          column={1}
          labelStyle={{ flex: 1 }}
          contentStyle={{ flex: 'inherit' }}
        >
          <Descriptions.Item label="Contract Address">
            <a
              rel="noreferrer"
              href={`https://rinkeby.etherscan.io/address/${tokenAddress}`}
              target="_blank"
            >
              {formatAddress(tokenAddress)}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Token ID">{tokenId}</Descriptions.Item>
          <Descriptions.Item label="Token Standard">
            {standard}
          </Descriptions.Item>
          <Descriptions.Item label="Blockchain">Rinkeby</Descriptions.Item>
        </Descriptions>
      </Panel>
    </Collapse>
  );
};

export default memo(Base);

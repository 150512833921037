export interface NFT {
  id: string;
  name: string;
  owner: string;
  image: string;
  backgroundColor: string;
  tokenAddress: string;
  tokenId: string;
  sellPrice: string;
  description?: string;
  standard: string;
}
export enum OrderType {
  /**
   * 销售
   */
  SALE = 1,
  /**
   * 出价
   */
  OFFER,
  /**
   * 拍卖
   */
  AUCTION,
}

export interface Order {
  tokenAddress: string;
  tokenId: string;
  maker: string;
  price: string;
  amount: number;
  listingTime: number;
  expirationTime: number;
  salt: string;
  hash?: string;
  signature?: string;
  type: OrderType;
}

import { FC, memo } from 'react';
import { NFT } from '../interface';

const SellHeader: FC<NFT> = ({ name }) => {
  return (
    <div className="sticky bg-neutral-50 top-[64px] left-0 z-50 h-16 border-b border-neutral-200">
      <div className="max-w-screen-xl mx-auto h-full flex items-center">
        {name}
      </div>
    </div>
  );
};

export default memo(SellHeader);

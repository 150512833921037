import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Descriptions } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, ContractFactory, ethers, utils } from 'ethers';
import dayjs from 'dayjs';
import { injected } from '@/config/connector';
import SimpleAuction from '@/contracts/SimpleAuction.json';
import NFT1155 from '@/contracts/NFT1155.json';
import './index.less';

const Home: FC = () => {
  const { library, account, activate } = useWeb3React();

  const handleConnectClick = () => {
    activate(injected);
  };

  const handleAuctionClick = async () => {
    if (library) {
      const signer = library.getSigner();
      // ethers.Contract()
      const factory = new ContractFactory(
        SimpleAuction.abi,
        SimpleAuction.bytecode,
        signer
      );
      const contract = await factory.deploy(
        dayjs('2021-11-30').unix(),
        account
      );

      console.log(contract.address);
      console.log(contract.deployTransaction);
      await contract.deployed();
      console.log('合约部署完成');
      // const contract = new ethers.Contract(
      //   'SimpleAuction(hengkx)',
      //   SimpleAuction.abi,
      //   signer,
      // );
      // console.log(contract);
    }
  };

  const handle1155DeployClick = async () => {
    if (library) {
      const signer = library.getSigner();
      const factory = new ContractFactory(
        NFT1155.abi,
        NFT1155.bytecode,
        signer
      );

      const contract = await factory.deploy();

      console.log(contract.address);
      console.log(contract.deployTransaction);
      await contract.deployed();
      console.log('合约部署完成');
      // const contract = new ethers.Contract(
      //   'SimpleAuction(hengkx)',
      //   SimpleAuction.abi,
      //   signer,
      // );
      // console.log(contract);
    }
  };

  const contract = useMemo(() => {
    if (library) {
      const signer = library.getSigner();
      // return new ethers.Contract(
      //   '0xC1D204818BEf822dC4Cf3f45A4e7C7bE1EC17BF0',
      //   SimpleAuction.abi,
      //   signer,
      // );
      return new ethers.Contract(
        '0x4cBC883815F657D424f50c9e5c95ABf86233e170',
        NFT1155.abi,
        signer
      );
      // await contract.bid({
      //   from: account,
      //   value: utils.parseEther('2'),
      // });
    }
  }, [library]);

  const [auctionEndTime, setAuctionEndTime] = useState<string>();
  const [highestBidder, setHighestBidder] = useState<string>();
  const [highestBid, setHighestBid] = useState<string>();

  const loadData = useCallback(async () => {
    if (contract) {
      const res = (await contract.auctionEndTime()) as BigNumber;
      setAuctionEndTime(
        dayjs.unix(res.toNumber()).format('YYYY-MM-DD HH:mm:ss')
      );
      setHighestBidder(await contract.highestBidder());
      setHighestBid(utils.formatEther(await contract.highestBid()));
      console.log(contract);

      // console.log(await contract.pendingReturns());
      contract.on('HighestBidIncreased', (bidder, amount) => {
        console.log(bidder, amount);
      });
      // let eventFilter = contract.filters.ContractEvent();
      let events = await contract.queryFilter({});
      console.log(events);
      // console.log(contract.provider);
      console.log('---------------');
      // https://api-rinkeby.etherscan.io/api?module=account&action=txlist&address=0xC1D204818BEf822dC4Cf3f45A4e7C7bE1EC17BF0&startblock=0&endblock=99999999&page=1&offset=10&sort=asc&apikey=465ZTAAG1FFW2PG7MZICQXZ5TDFHAHDUVJ
      // let events1 = await contract.provider.getLogs({
      //   fromBlock: 0,
      //   toBlock: 99999999,
      //   address: '0xC1D204818BEf822dC4Cf3f45A4e7C7bE1EC17BF0',
      // });
      // console.log(events1);
    }
  }, [contract]);

  useEffect(() => {
    // loadData();
  }, [loadData]);

  const handlePayClick = async () => {
    if (contract) {
      await contract.bid({
        from: account,
        value: utils.parseEther('2'),
      });
    }
  };

  const handleWithdrawClick = async () => {
    if (contract) {
      console.log(await contract.withdraw());
    }
  };
  console.log(contract);

  const handleCreateCollectionClick = async () => {
    if (contract) {
      // const res = await contract.mint(account, 2, 5, 0);
      // console.log(res);
      // const res = await contract.setURI('https://cv.arweave.net/{id}');
      // console.log(res);
      const res = await contract.createCollection([
        'Test',
        [['0x5b3f047a7Db07fC6F545816F13dFC3DbC8Adb2DB', 100]],
        [['0x5b3f047a7Db07fC6F545816F13dFC3DbC8Adb2DB', 100]],
        200,
      ]);
      console.log(res);
    }
  };

  const handle1155MintClick = async () => {
    if (contract) {
      const res = await contract.mint(
        'Test',
        account,
        2,
        20,
        'https://4chdy7nzhyneeemy5zgnudwqzkp37zt4rfxwpc7ybv5ie5qsme7a.arweave.net/4I48fbk-GkIRmO5M2g7Qyp-_5nyJb2eL-A16gnYSYT4',
        0
      );
      console.log(res);
      const r = await res.wait();
      console.log(r);
      // const res = await contract.setURI('https://cv.arweave.net/{id}');
      // console.log(res);
      // const res = await contract.uri(2);
      // console.log(res);
    }
  };

  const handleSetPriceClick = async () => {
    if (contract) {
      const res = await contract.setPrice(2, utils.parseEther('1'));
      console.log(res);
      // const res = await contract.setURI('https://cv.arweave.net/{id}');
      // console.log(res);
      // const res = await contract.uri(2);
      // console.log(res);
    }
  };

  const handleBuyClick = async () => {
    if (contract) {
      const res = await contract.buy(2, { value: utils.parseEther('1') });
      console.log(res);
      // const res = await contract.setURI('https://cv.arweave.net/{id}');
      // console.log(res);
      // const res = await contract.uri(2);
      // console.log(res);
    }
  };

  const handleApprovalClick = async () => {
    if (contract) {
      // const res = await contract.setApprovalForAll(
      //   // '0x743CeE87CAa735e6B18Ce00Df4Be3F38F6384534',
      //   '0x5b3f047a7Db07fC6F545816F13dFC3DbC8Adb2DB',
      //   true,
      // );
      const res = await contract.isApprovedForAll(
        '0x743CeE87CAa735e6B18Ce00Df4Be3F38F6384534',
        '0x5b3f047a7Db07fC6F545816F13dFC3DbC8Adb2DB'
      );
      console.log(res);
    }
  };

  return (
    <div className="home">
      {account}
      <Descriptions column={1}>
        <Descriptions.Item label="最高出价">{highestBid}ETH</Descriptions.Item>
        <Descriptions.Item label="最高出价人">
          {highestBidder}
        </Descriptions.Item>
        <Descriptions.Item label="结束时间">{auctionEndTime}</Descriptions.Item>
      </Descriptions>

      <Button onClick={handleConnectClick}>连接</Button>
      <Button onClick={handleAuctionClick}>拍卖</Button>
      <Button onClick={handlePayClick}>出价</Button>
      <Button onClick={handleWithdrawClick}>提现</Button>
      <Button onClick={handle1155DeployClick}>1155部署</Button>
      <Button onClick={handle1155MintClick}>1155铸造</Button>
      <Button onClick={handleCreateCollectionClick}>创建Collection</Button>
      <Button onClick={handleSetPriceClick}>设置价格</Button>
      <Button onClick={handleBuyClick}>购买</Button>
      <Button onClick={handleApprovalClick}>授权</Button>
    </div>
  );
};

export default Home;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { SWRConfig } from 'swr';
import App from './layouts';
import reportWebVitals from './reportWebVitals';
import './index.less';

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:5001/api/blockchain';
} else {
  axios.defaults.baseURL = 'https://api.privacy.hengzwl.com/api/blockchain';
}

axios.interceptors.response.use(
  ({ data }) => data,
  (error) => ({ code: 500, message: error.message })
);

const getLibrary = (provider: any) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
};

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig
      value={{ fetcher: (args) => axios(args).then((res) => res.data) }}
    >
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Web3ReactProvider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { FC, memo } from 'react';
import { Button, Space } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import Market from '@/contracts/MarketV2.json';
import { marketAddress } from '@/config';
import '../list.less';
import { NFT, Order } from '../interface';
import { getOrderHash, toOrderTuple } from '../utils';

interface DetailHeaderProps extends NFT {
  orders: Order[];
}

const DetailHeader: FC<DetailHeaderProps> = ({ orders }) => {
  const navigate = useNavigate();
  const { library, chainId } = useWeb3React();

  const handleCancelClick = async () => {
    if (library) {
      const signer = library.getSigner();
      const contract = new ethers.Contract(marketAddress, Market.abi, signer);
      const hash = getOrderHash(orders[0], chainId);
      console.log(hash);
      console.log('----', await contract.hashOrder(toOrderTuple(orders[0])));
      let res = await Promise.all(
        orders.map((order) =>
          contract.cancelOrder(toOrderTuple(order), order.signature)
        )
      );
      res = await Promise.all(res.map((item) => item.wait()));
      console.log(res);
    }
  };

  return (
    <div className="sticky bg-neutral-50 top-[64px] left-0 z-50 h-16 border-b border-neutral-200">
      <div className="max-w-screen-xl mx-auto h-full flex flex-row-reverse items-center">
        <Space>
          {orders.length > 0 ? (
            <Button className="w-32" size="large" onClick={handleCancelClick}>
              Cancel listing
            </Button>
          ) : (
            <Button
              className="w-32"
              size="large"
              type="primary"
              onClick={() => navigate('sell')}
            >
              Sell
            </Button>
          )}
        </Space>
      </div>
    </div>
  );
};

export default memo(DetailHeader);

import { Route, Routes } from 'react-router';
import Auction from '@/pages/auction';
import Home from '@/pages/home';
import AssetCreate from '@/pages/asset/create';
import AssetList from '@/pages/asset/list';
import AssetDetail from '@/pages/asset/detail';
import AssetSell from '@/pages/asset/sell';
import Collection from '@/pages/collection';
import Login from '@/pages/account/Login';
import { useEagerConnect } from '@/hooks';
import Navbar from './Navbar';

const App = () => {
  useEagerConnect();

  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/auction" element={<Auction />} />
        <Route path="/asset/create" element={<AssetCreate />} />
        <Route path="/assets/:address/:tokenId/sell" element={<AssetSell />} />
        <Route path="/assets/:address/:tokenId" element={<AssetDetail />} />
        <Route path="/account" element={<AssetList />} />
        <Route path="/collection/:address/create" element={<AssetCreate />} />
        <Route path="/collection/:address" element={<Collection />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
};

export default App;

import { FC, useState } from 'react';
import { Button, Form, InputNumber } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { Contract, utils } from 'ethers';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ERC721Tradable from '@/contracts/ERC721Tradable.json';
import Market from '@/contracts/MarketV2.json';
import { marketAddress } from '@/config';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { NFT } from '../interface';
import SellHeader from './SellHeader';
import { getOrderHash, toOrderTuple } from '../utils';

const Sell: FC = () => {
  const { library, account, chainId } = useWeb3React();
  const { address, tokenId } = useParams<any>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { data } = useSWR<NFT>(`/nft/${address}/${tokenId}`);

  const handleFinish = async (values: any) => {
    if (!library || !account || !address || !tokenId) {
      return;
    }
    try {
      setLoading(true);
      const signer = library.getSigner();

      const order = {
        tokenAddress: address,
        tokenId,
        maker: account,
        price: utils.parseEther(`${values.price}`).toString(),
        amount: 1,
        listingTime: dayjs().unix(),
        expirationTime: dayjs().add(7, 'day').unix(),
        salt: `${dayjs().unix()}`,
        type: 1,
      };

      const contract = new Contract(marketAddress, Market.abi, signer);
      const isApproved = await contract.isApproved(toOrderTuple(order));
      if (!isApproved) {
        const nft = new Contract(address, ERC721Tradable.abi, signer);
        await nft.setApprovalForAll(marketAddress, true);
      }

      const hash = getOrderHash(order, chainId);
      console.log(hash);
      const signature = await signer.signMessage(utils.arrayify(hash));
      axios.post('/nft/sell', {
        ...order,
        hash,
        signature,
      });
      navigate(-1);
    } catch (error) {
      setLoading(false);
    }
  };

  if (!data) {
    return <div />;
  }
  return (
    <>
      <SellHeader {...data} />
      <div className="max-w-screen-xl mx-auto py-8">
        <Form layout="vertical" onFinish={handleFinish}>
          <Form.Item name="price" label="Price" rules={[{ required: true }]}>
            <InputNumber
              addonBefore="ETH"
              min={0.0001}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="w-32"
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Sell
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Sell;

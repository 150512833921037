import { FC, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Upload } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, Contract } from 'ethers';
import { create } from 'ipfs-http-client';
import ERC721Tradable from '@/contracts/ERC721Tradable.json';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { nftAddress } from '@/config';

const client = create({ url: 'https://ipfs.infura.io:5001' });

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const Create: FC = () => {
  const { library, account } = useWeb3React();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [form] = Form.useForm();

  const handleCreate = async () => {
    // // https://ipfs.infura.io/ipfs/QmaEqs7MXcvM1kJwfsVuuWq9BGQdhCdBwGML3DvGPLVcPt
    // const { path } = await client.add(
    //   JSON.stringify({
    //     name: 'Tom Xu Test Collection New',
    //     description: 'Tom Xu Test Description',
    //     seller_fee_basis_points: 1000,
    //     fee_recipient: '0x9454c9090074e7377ed6f8645708Dd529B3b0C15',
    //     image:
    //       'https://ipfs.infura.io/ipfs/QmUmD6eFqVAoJaiSBH7ZBnKw9quKogt15m5QufnbAPVGNu',
    //   })
    // );
    // const url = `https://ipfs.infura.io/ipfs/${path}`;
    // console.log(url);

    if (library) {
      setSaveLoading(true);
      const signer = library.getSigner();
      const contract = new Contract(nftAddress, ERC721Tradable.abi, signer);
      await form.validateFields();
      const values = await form.getFieldsValue();
      const { path } = await client.add(
        JSON.stringify({
          ...values,
          image: imageUrl,
        })
      );
      const url = `https://ipfs.infura.io/ipfs/${path}`;
      const res = await contract.mint(account, url);
      const confirmRes = await res.wait();
      const tokenId = confirmRes.events[0].args[2] as BigNumber;
      console.log(tokenId.toString());
      await axios.post('/nft', {
        ...values,
        image: imageUrl,
        tokenAddress: nftAddress,
        tokenId: tokenId.toString(),
        uri: url,
        owner: account,
        standard: 'ERC-721',
      });
      navigate(`/assets/${nftAddress}/${tokenId}`);
      setSaveLoading(false);
    }
  };

  return (
    <div className="container mx-auto py-20">
      <Row>
        <Col span={12} offset={6}>
          <Form form={form} layout="vertical">
            <Form.Item
              label="图片"
              name="image"
              valuePropName="fileList"
              rules={[{ required: true, message: '请上传图片' }]}
              getValueFromEvent={(e) => {
                console.log(e);
                return e.fileList;
              }}
            >
              <Upload
                listType="picture-card"
                className="avatar-upload"
                showUploadList={false}
                beforeUpload={beforeUpload}
                maxCount={1}
                customRequest={async ({ file, onSuccess }) => {
                  setLoading(true);
                  const res = await client.add(file);
                  const url = `https://ipfs.infura.io/ipfs/${res.path}`;
                  setImageUrl(url);
                  setLoading(false);
                  onSuccess?.(res);
                }}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                ) : (
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>上传</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              label="名称"
              name="name"
              rules={[{ required: true, message: '请填写名称' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="描述" name="description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <Button
                loading={saveLoading}
                type="primary"
                onClick={handleCreate}
              >
                创建
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Create;

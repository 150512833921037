import { FC, useCallback, useEffect, useState } from 'react';
import { Col, Row, Space } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers } from 'ethers';
import axios from 'axios';
import { Link } from 'react-router-dom';
import NFTArtifact from '@/contracts/NFT.json';
import './list.less';

interface NFT {
  name: string;
  description: string;
  image: string;
  tokenId: string;
}

const Home: FC = () => {
  const { library, account } = useWeb3React();

  const [items, setItems] = useState<NFT[]>([]);

  const loadData = useCallback(async () => {
    if (library) {
      const contract = new ethers.Contract(
        'nftAddress',
        NFTArtifact.abi,
        library
      );
      let events = await contract.queryFilter({});
      const res: any = await Promise.all(
        events
          .filter(
            (p) => p.event === 'Transfer' && p.args?.indexOf(account) !== -1
          )
          .map(async (p) => {
            const tokenId = (p.args![2] as BigNumber).toString();
            const url = await contract.tokenURI(p.args![2]);
            const content = await axios.get(url);
            return { ...content, tokenId };
          })
      );
      setItems(res);
    }
  }, [library, account]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="asset-list">
      <Row gutter={[30, 30]}>
        {items.map((item) => (
          <Col key={item.tokenId}>
            <Link to={`/asset/${item.tokenId}`}>
              <Space className="asset-list-item" direction="vertical">
                <div className="img-container">
                  <img src={item.image} alt="asset" />
                </div>
                <div className="asset-list-item-name">{item.name}</div>
              </Space>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Home;

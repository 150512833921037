import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Input, Menu } from 'antd';
import {
  SearchOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { useWeb3React } from '@web3-react/core';
import logo from '@/images/logo.png';
import Wallet from './Wallet';
import './index.less';

// const { SubMenu } = Menu;

const Navbar: FC = () => {
  const { active } = useWeb3React();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const handleWalletClick = (e: React.MouseEvent) => {
    if (!visible) {
      e.stopPropagation();
    }
    if (active) {
      setVisible(true);
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <header className="navbar" onClick={() => setVisible(false)}>
        <div className="flex items-center justify-center h-full px-8">
          <Link
            to="/"
            className="h-full flex items-center justify-center text-xl font-bold text-black hover:text-black"
          >
            <img className="w-10 pr-2" src={logo} alt="logo" />
            Blockchain
          </Link>
          <Input className="flex-1 mx-8" prefix={<SearchOutlined />} />
          <Menu mode="horizontal" style={{ width: 100 }}>
            <Menu.Item key="home">
              <Link to="/asset/create" className="block">
                Create
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="auction">
        <Link to="/auction">拍卖</Link>
      </Menu.Item>
      <Menu.Item key="app">
        <Link to="/asset/create">创建</Link>
      </Menu.Item>
      <SubMenu key="SubMenu" title="Navigation Three - Submenu">
        <Menu.ItemGroup title="Item 1">
          <Menu.Item key="setting:1">Option 1</Menu.Item>
          <Menu.Item key="setting:2">Option 2</Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Item 2">
          <Menu.Item key="setting:3">Option 3</Menu.Item>
          <Menu.Item key="setting:4">Option 4</Menu.Item>
        </Menu.ItemGroup>
      </SubMenu>
      <Menu.Item key="alipay">
        <Link to="/account">资产</Link>
      </Menu.Item> */}
          </Menu>
          <Avatar
            icon={<UserOutlined />}
            className="items-center justify-center"
            style={{ display: 'flex' }}
          />
          <WalletOutlined
            className="ml-4"
            style={{ fontSize: 24, color: 'rgba(0,0,0,0.5)' }}
            onClick={handleWalletClick}
          />
        </div>
      </header>
      <Wallet visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};

export default Navbar;

import { _TypedDataEncoder } from '@ethersproject/hash';
import { BigNumberish } from 'ethers';
import { marketAddress } from '@/config';
import { Order } from './interface';

export function toOrderTuple(order: Order) {
  return [
    order.tokenAddress,
    order.tokenId,
    order.maker,
    order.price,
    order.amount,
    order.listingTime,
    order.expirationTime,
    order.salt,
  ];
}

export function getOrderHash(order: Order, chainId?: BigNumberish) {
  const domain = {
    name: 'Tom Xu',
    version: '1.0.0',
    chainId,
    verifyingContract: marketAddress,
  };

  const types = {
    Order: [
      { name: 'tokenAddress', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'maker', type: 'address' },
      { name: 'price', type: 'uint256' },
      { name: 'amount', type: 'uint256' },
      { name: 'listingTime', type: 'uint256' },
      { name: 'expirationTime', type: 'uint256' },
      { name: 'salt', type: 'uint256' },
    ],
  };

  return _TypedDataEncoder.hash(domain, types, order);
}

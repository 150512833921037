import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

export const RPC_URLS: { [chainId: number]: string } = {
  1: process.env.RPC_URL_1 as string,
  4: process.env.RPC_URL_4 as string,
};

const supportChainIdList = [
  1, // Mainet
  3, // Ropsten
  4, // Rinkeby
  5, // Goerli
  42, // Kovan
  137,
  1337,
  80001,
];

export const injected = new InjectedConnector({
  supportedChainIds: supportChainIdList,
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  qrcode: true,
});

import { FC, memo, useMemo } from 'react';
import { Avatar, Drawer, Dropdown, List, Menu } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useWeb3React } from '@web3-react/core';
import useSWR from 'swr';
import { utils } from 'ethers';
import { formatAddress } from '@/utils';
import { Icon } from '@/components';

interface WalletProps {
  visible: boolean;
  onClose?: () => void;
}

interface WalletItem {
  symbol: string;
  balance: string;
  chain: string;
}

const iconMap: Record<string, string> = {
  'ETH-Rinkeby': 'eth',
  'WETH-Rinkeby': 'weth',
  'WETH-Mumbai': 'weth-mumbai',
};

const Wallet: FC<WalletProps> = ({ visible, onClose }) => {
  const { account, deactivate } = useWeb3React();

  const { data = [], mutate } = useSWR<WalletItem[]>(() =>
    account ? `/balances/${account}` : null
  );

  const title = useMemo(
    () => (
      <div className="flex items-center justify-between">
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item key="logout" onClick={() => deactivate()}>
                Log out
              </Menu.Item>
              <Menu.Item key="refresh" onClick={() => mutate()}>
                Refresh funds
              </Menu.Item>
            </Menu>
          }
        >
          <div className="flex items-center justify-between cursor-pointer">
            <Avatar
              icon={<UserOutlined />}
              className="items-center justify-center"
              style={{ display: 'flex' }}
            />
            <div className="mx-2">My wallet</div>
            <DownOutlined />
          </div>
        </Dropdown>
        <div className="text-gray-400 font-normal">
          {formatAddress(account)}
        </div>
      </div>
    ),
    [account, deactivate, mutate]
  );

  return (
    <Drawer
      closable={false}
      width={400}
      title={title}
      placement="right"
      onClose={onClose}
      visible={visible}
      style={{ top: 64 }}
    >
      <List
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <div className="flex items-center w-full">
              <Icon
                type={iconMap[`${item.symbol}-${item.chain}`]}
                style={{ fontSize: 22 }}
              />
              <div className="ml-4 flex-1">
                <div className="flex justify-between font-bold">
                  <div>{item.symbol}</div>
                  <div>
                    {parseFloat(utils.formatEther(item.balance)).toFixed(4)}
                  </div>
                </div>
                <div className="text-gray-400">{item.chain}</div>
              </div>
            </div>
          </List.Item>
        )}
      />
    </Drawer>
  );
};

export default memo(Wallet);

import { FC, memo, useCallback } from 'react';
import { Button, Collapse, Space, Table } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { Contract, utils } from 'ethers';
import Market from '@/contracts/MarketV2.json';
import { marketAddress } from '@/config';
import { Order, OrderType } from '../interface';
import { toOrderTuple } from '../utils';
import { formatAddress } from '@/utils';

const { Panel } = Collapse;

interface ListingProps {
  title: string;
  isOwner: boolean;
  orders: Order[];
}

const Listing: FC<ListingProps> = ({ title, orders, isOwner }) => {
  const { library, account } = useWeb3React();

  const handleCancelSaleClick = useCallback(
    async (order: Order) => {
      if (library) {
        const signer = library.getSigner();
        const contract = new Contract(marketAddress, Market.abi, signer);
        const res = await contract.cancelOrder(
          toOrderTuple(order),
          order.signature
        );
        console.log(res);
      }
    },
    [library]
  );

  const handleAcceptOfferClick = useCallback(
    async (order: Order) => {
      if (library) {
        const signer = library.getSigner();
        const contract = new Contract(marketAddress, Market.abi, signer);
        const res = await contract.acceptOffer(
          toOrderTuple(order),
          order.signature,
          1
        );
        console.log(res);
      }
    },
    [library]
  );

  const renderAction = useCallback(
    (_value: any, order: Order) => {
      if (order.type === OrderType.SALE) {
        return isOwner ? (
          <Button onClick={() => handleCancelSaleClick(order)}>Cancel</Button>
        ) : (
          <Button>Buy</Button>
        );
      }
      return isOwner ? (
        <Space>
          <Button onClick={() => handleCancelSaleClick(order)}>Counter</Button>
          <Button onClick={() => handleAcceptOfferClick(order)}>Accept</Button>
        </Space>
      ) : (
        <Space>
          {order.maker === account && (
            <Button onClick={() => handleCancelSaleClick(order)}>Cancel</Button>
          )}
          {/* <Button onClick={() => handleCancelSaleClick(order)}>Accept</Button> */}
        </Space>
      );
    },
    [account, isOwner, handleCancelSaleClick, handleAcceptOfferClick]
  );

  return (
    <Collapse
      defaultActiveKey={orders.length > 0 ? ['listings'] : []}
      expandIconPosition="right"
    >
      <Panel header={title} key="listings">
        <Table rowKey="id" dataSource={orders} pagination={false}>
          <Table.Column
            title="Price"
            dataIndex="price"
            render={(value) => utils.formatEther(value)}
          />
          <Table.Column title="Amount" dataIndex="amount" />
          <Table.Column
            title="From"
            dataIndex="maker"
            render={(value) =>
              value === account ? 'you' : formatAddress(value)
            }
          />
          <Table.Column title="" dataIndex="id" render={renderAction} />
        </Table>
      </Panel>
    </Collapse>
  );
};

export default memo(Listing);
